import { graphql } from 'gatsby';
import React, { ReactElement } from 'react';
import HomeTemplate from 'templates/Home';
import { Rental } from 'types/api';

type Props = {
    data: {
        highlights: {
            nodes: Array<Rental>;
        };
        notHighlights: {
            nodes: Array<Rental>;
        };
    };
};

const Home = ({
    data: {
        highlights: { nodes: highlighteds },
        notHighlights: { nodes: rentals },
    },
}: Props): ReactElement => <HomeTemplate highlighteds={highlighteds} rentals={rentals} />;

export const query = graphql`
    {
        highlights: allRental(
            filter: { positionHighlighted: { ne: null } }
            limit: 4
            sort: { fields: [positionHighlighted], order: ASC }
        ) {
            nodes {
                id
                title
                city
                slug
                published
                bedroomCount
                mainPicture {
                    id
                    cdnId
                    format
                    contentUrl
                }
                hasSpecialOffers
                location {
                    title
                }
                prices {
                    price
                    bedroomCount
                    season {
                        id
                        periodStart
                        periodEnd
                    }
                }
            }
        }
        notHighlights: allRental {
            nodes {
                id
                title
                city
                slug
                published
                bedroomCount
                mainPicture {
                    id
                    cdnId
                    format
                    contentUrl
                }
                hasSpecialOffers
                location {
                    title
                }
                prices {
                    price
                    bedroomCount
                    season {
                        id
                        periodStart
                        periodEnd
                    }
                }
            }
        }
    }
`;

export default Home;
