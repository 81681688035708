import React, { ReactElement } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
    picture: string;
    title: string;
    content: string;
};

const Tile = ({ picture, title, content }: Props): ReactElement => {
    const { t } = useTranslation('home');

    return (
        <Col xs={12} md={6} className="mb-4 align-items-center">
            <Row>
                <Col xs={12} lg={6}>
                    <Image fluid src={picture} className="w-100" alt={title} />
                </Col>
                <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                    <strong className="text-uppercase">
                        <Trans t={t}>{title}</Trans>
                    </strong>
                    <p>
                        <Trans t={t}>{content}</Trans>
                    </p>
                </Col>
            </Row>
        </Col>
    );
};

export default Tile;
