import { cssBreakpoint } from 'device';
import main from 'images/main.jpg';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const ContentContainer = styled(Container).attrs(() => ({
    className: 'p-0',
    fluid: true,
}))`
    margin-top: -${(props): string => props.theme.ivr.header.height};
    @media screen and ${cssBreakpoint('xl')} {
        margin-top: calc(-${(props): string => props.theme.ivr.header.height} - 15px);
    }
`;

const MainPictureContainer = styled.div`
    background-image: url(${main});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 650px;
`;

const Journey = styled.h3.attrs(() => ({
    className: 'text-center fw-lighter',
}))`
    color: ${(props): string => props.theme.ivr.colors.white[100]};
    font-family: ${(props): string => props.theme.ivr.fonts.playfair};
    font-size: 1.8rem;
    font-style: italic;
    padding-top: 100px;

    @media screen and ${cssBreakpoint('xl')} {
        font-size: 2rem;
    }
`;

const H2 = styled.h2.attrs(() => ({
    className: 'mt-3 mb-2',
}))`
    font-family: ${(props): string => props.theme.ivr.fonts.playfair};
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 700;
    position: relative;
`;

export { ContentContainer, H2, Journey, MainPictureContainer };
