import selectStyles from 'components/Form/selectStyles';
import TopLabel from 'components/Form/TopLabel';
import useSSR from 'hooks/useSSR';
import useStickyState from 'hooks/useStickyState';
import React, { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Select, { OptionTypeBase } from 'react-select';
import * as api from 'services/rental';
import { MinMaxRange } from 'types/api';

type Props = {
    label?: boolean;
};

const initialBedroomRange: MinMaxRange = { min: 1, max: 10 };

const BedroomSelect = ({ label }: Props): ReactElement => {
    const [bedroomRange, setBedroomRange] = useStickyState<MinMaxRange>('bedroom_range', initialBedroomRange);
    const { min, max } = bedroomRange;
    const { browser } = useSSR();

    useQuery(['rental_aggregate_bedroom_range'], api.bedroomRange, {
        enabled: browser,
        select: (response) => response.data,
        onSuccess: (data: MinMaxRange) => setBedroomRange(data),
    });

    const { t } = useTranslation(['form']);
    const { control } = useFormContext<{ bedrooms: number }>();

    const options: Array<OptionTypeBase> = [];
    for (let i = parseInt(min.toString(), 10); i <= max; i++) {
        options.push({
            value: i.toString(),
            label: t('form:search.bedroom_option', { count: i }),
        });
    }

    return (
        <Controller
            control={control}
            name="bedrooms"
            render={({ field }): ReactElement => {
                const handleChange = (value: any): void => field.onChange(value.value);

                return (
                    <div className="position-relative">
                        {label && <TopLabel>{t('form:search.bedrooms')}</TopLabel>}
                        <Select
                            placeholder={t('search.bedrooms')}
                            options={options}
                            styles={selectStyles}
                            isSearchable={false}
                            {...field}
                            value={options.find(
                                (option) => parseInt(option.value, 10) === parseInt(field.value.toString(), 10),
                            )}
                            onChange={handleChange}
                        />
                    </div>
                );
            }}
        />
    );
};

export default BedroomSelect;
