import BedroomSelect from 'components/Form/BedroomSelect';
import DatePickerInput from 'components/Form/DatePickerInput';
import DateRangePicker from 'components/Form/DateRangePicker';
import SubmitButton from 'components/Form/SubmitButton';
import { parseISO } from 'date-fns';
import useLanguage from 'hooks/useLanguage';
import useStickyState from 'hooks/useStickyState';
import React, { ReactElement, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { HotelDatepickerRef, InputElementProps } from 'react-hotel-datepicker';
import { useTranslation } from 'react-i18next';
import { SearchFormContainer } from './styled';
import { navigate } from 'gatsby';

const SearchForm = (): ReactElement => {
    const [periodStart, setPeriodStart] = useStickyState<string>('periodStart', '');
    const [periodEnd, setPeriodEnd] = useStickyState<string>('periodEnd', '');
    const [bedrooms, setBedrooms] = useStickyState<number>('bedrooms', 1);
    const { f639 } = useLanguage();
    const { t } = useTranslation(['form']);
    const ref = useRef<HotelDatepickerRef>(null);

    const form = useForm<SearchFormValues>({
        defaultValues: {
            periodStart,
            periodEnd,
            bedrooms,
        },
    });

    const { handleSubmit } = form;

    const onSubmit = (values: SearchFormValues): void => {
        setPeriodStart(values.periodStart);
        setPeriodEnd(values.periodEnd);
        setBedrooms(values.bedrooms);

        // noinspection JSIgnoredPromiseFromCall
        navigate(f639 === 'fr' ? '/locations' : '/rentals');
    };

    const startDate = periodStart.length > 0 ? parseISO(periodStart) : null;
    const endDate = periodEnd.length > 0 ? parseISO(periodEnd) : null;

    return (
        <FormProvider {...form}>
            <SearchFormContainer>
                <Form className="pt-1" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mx-0">
                        <Col xs={12} lg={6}>
                            <DateRangePicker
                                input={({ value, onClick }: InputElementProps): ReactElement => (
                                    <DatePickerInput
                                        value={value}
                                        onClick={onClick}
                                        handleClear={(): void => ref.current?.clear()}
                                    />
                                )}
                                defaultStart={startDate}
                                defaultEnd={endDate}
                                ref={ref}
                            />
                        </Col>
                        <Col xs={12} lg={3} className="form-group-select form-group-select-bedroom">
                            <BedroomSelect />
                        </Col>
                        <Col xs={12} lg={3} className="d-flex">
                            <SubmitButton type="submit" variant="primary" className="text-uppercase w-100">
                                {t('search.submit')}
                            </SubmitButton>
                        </Col>
                    </Row>
                </Form>
            </SearchFormContainer>
        </FormProvider>
    );
};

export default SearchForm;
