import H1 from 'components/Layout/H1';
import RentalCard from 'components/Rental/Card';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Rental } from 'types/api';

type Props = {
    rentals: Array<Rental>;
};

const HighlightedRentals: React.FC<Props> = ({ rentals, children }) => {
    const { t } = useTranslation('home');

    return (
        <Container>
            {rentals.length > 0 && (
                <React.Fragment>
                    <div className="text-center">
                        <H1>{t('home:our_selection')}</H1>
                    </div>
                    <Row>
                        {rentals.map((rental) => (
                            <RentalCard
                                rental={{
                                    ...rental,
                                }}
                                xs={12}
                                md={6}
                                key={rental.id}
                            />
                        ))}
                    </Row>
                </React.Fragment>
            )}
            {children}
        </Container>
    );
};

export default HighlightedRentals;
