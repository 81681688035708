import * as React from 'react';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Meta = (): ReactElement => {
    const { t, i18n } = useTranslation('home');
    return (
        <Helmet>
            <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}?_locale=${i18n.language}`} />
            <meta property="og:site_name" content={t('meta.og.siteName')} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={t('meta.og.title')} />
            <meta property="og:description" content={t('meta.og.description')} />
            <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
            <meta property="twitter:title" content={t('meta.twitter.title')} />
            <meta property="twitter:description" content={t('meta.twitter.description')} />
            <meta property="twitter:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
            <meta name="twitter:card" content="summary" />
            <meta property="title" content={t('meta.title')} />
            <title>{t('meta.title')}</title>
            <meta property="description" content={t('meta.description')} />
        </Helmet>
    );
};

export default Meta;
