import HighlightedRentals from 'components/Home/HighlightedRentals';
import Meta from 'components/Home/Meta';
import SearchForm from 'components/Home/SearchForm';
import Tile from 'components/Home/Tile';
import GreyContainer from 'components/Layout/GreyContainer';
import LinkButton from 'components/Shared/LinkButton';
import useSSR from 'hooks/useSSR';
import arrival from 'images/home/tiles/arrival.png';
import service from 'images/home/tiles/bell.png';
import booking from 'images/home/tiles/room.png';
import stay from 'images/home/tiles/stay.png';
import ideal from 'images/ideal.png';
import * as React from 'react';
import { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { randomRentals } from 'services/rental';
import { Rental } from 'types/api';
import { ContentContainer, H2, Journey, MainPictureContainer } from './styled';

type Props = {
    highlighteds: Array<Rental>;
    rentals: Array<Rental>;
};

const HomeTemplate = ({ highlighteds, rentals }: Props): ReactElement => {
    const { browser } = useSSR();
    const { data } = useQuery('randoms', randomRentals, {
        select: (response) => response.data['hydra:member'].map((member): string => member['@id']),
        enabled: browser,
    });
    const randoms = rentals.filter((rental) => (data || []).includes(rental.id));
    const { t, i18n } = useTranslation('home');
    const locale = (i18n.language || 'fr').substr(0, 2) as 'fr' | 'en';

    return (
        <React.Fragment>
            <Meta />
            <ContentContainer>
                <MainPictureContainer>
                    <Row className="m-0">
                        <Col xs={12}>
                            <Journey>{t('title')}</Journey>
                        </Col>
                    </Row>
                    <Container className="g-2">
                        <Row className="m-0">
                            <Col xs={12} xl={{ span: 10, offset: 1 }}>
                                <SearchForm />
                            </Col>
                        </Row>
                    </Container>
                </MainPictureContainer>
                <Container className="pt-3 pt-xl-5 g-2">
                    <Row className="m-0">
                        <Col xs={12} xl={4}>
                            <img className="img-fluid mx-auto d-block" alt="Villa rental St Barth" src={ideal} />
                        </Col>
                        <Col xs={12} xl={8}>
                            <p>{t('intro')}</p>
                        </Col>
                    </Row>
                </Container>
                <GreyContainer className="flex-grow-1 mt-2 g-2">
                    <HighlightedRentals rentals={highlighteds} />
                </GreyContainer>
                <Container className="g-2">
                    <H2>
                        <Trans t={t}>home:our_services</Trans>
                    </H2>
                    <Row className="mt-3">
                        <Tile picture={service} title="home:tiles.service.title" content="home:tiles.service.content" />
                        <Tile picture={booking} title="home:tiles.booking.title" content="home:tiles.booking.content" />
                        <Tile picture={arrival} title="home:tiles.arrival.title" content="home:tiles.arrival.content" />
                        <Tile picture={stay} title="home:tiles.stay.title" content="home:tiles.stay.content" />
                    </Row>
                </Container>
                <GreyContainer className="flex-grow-1 py-5 g-2">
                    <HighlightedRentals rentals={randoms}>
                        <Row>
                            <Col xs={12} className="text-center">
                                <LinkButton to={'fr' === locale ? '/locations': '/rentals'} className="w-auto px-5">
                                    <Trans t={t}>home:see_all_rentals</Trans>
                                </LinkButton>
                            </Col>
                        </Row>
                    </HighlightedRentals>
                </GreyContainer>
            </ContentContainer>
        </React.Fragment>
    );
};

export default HomeTemplate;
