import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const SubmitButton = styled(Button).attrs(() => ({
    type: 'submit',
    variant: 'primary',
}))`
    && {
        background: ${(props): string => props.theme.ivr.buttons.submit.base.backgroundColor};
        border-color: ${(props): string => props.theme.ivr.buttons.submit.base.borderColor};
        margin: 10px 0;

        &:active,
        &:hover,
        &:focus {
            background: ${(props): string => props.theme.ivr.buttons.submit.hover.backgroundColor};
            border-color: ${(props): string => props.theme.ivr.buttons.submit.hover.borderColor};
            box-shadow: none;
        }
    }
`;

export default SubmitButton;
