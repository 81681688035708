import styled from 'styled-components';

const SearchFormContainer = styled.div.attrs(() => ({
    className: 'w-100',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.deepBlue[75]};
    margin-top: 25px;
`;

export { SearchFormContainer };
